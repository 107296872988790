import { Navigation } from 'swiper';
import { Card, Image } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DataCardsHome } from '../../_DATA/DataCardsHome';

export const HomeService = () => {

    const matches = useMediaQuery('(min-width:992px)');

    return <div>

        <div id='id_services' className="title_content">
            <span className="color_red">{"["}</span> Servicios <span className="color_red">{"]"}</span>
        </div>

        <div className="subhead_description">
            Entendemos las necesidades del mundo real y los problemas que conllevan afrontarlos. <br />
            <span style={{ background: "black", padding: "1%", color: "#dfdfdf" }}>Estamos para solventar y simplificar esos problemas</span>
        </div>

        <div style={{ padding: "5%" }} className="app_home_section_services_swiper_content">
            <Swiper slidesPerView={matches ? 4 : 1} centeredSlides={false}
                spaceBetween={20} pagination={{
                    type: "fraction",
                }} navigation={true} modules={[Navigation]} className="home_swiper_content">
                {
                    DataCardsHome.services.map((e, i) => <SwiperSlide key={i}>
                        <Card className="home_swiper_card_services">
                            <div className="home_swiper_title_service">
                                {e.title}
                            </div>
                            <Card.Body className="home_swiper_body_service">
                                <div>
                                    <Image className="vibrate_slow_infinite" width={100} src={e.img} />
                                </div>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>)
                }
            </Swiper>
        </div>
    </div>
}