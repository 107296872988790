import { SiClockify } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import { DataCardProjects } from "../../_DATA/DataCardProjects";
import { Col, Row, Card, Image, Badge, Alert } from "react-bootstrap";

export const WorksMain = () => {

    return <>

        <div className="DPX__gray_clear_content">

            <div style={{ marginBottom: "5%" }} className="DPX__head_title_section_content">
                Trabajos<span className="color_red">{`(${DataCardProjects.works.length})`}</span>
            </div>

            {
                DataCardProjects.works.length === 0 ? <Row>
                    <Col md={7} style={{ margin: "0 auto" }}>
                        <Alert variant="secondary"  >
                            <Alert.Heading>PRÓXIMAMENTE <SiClockify color="#000" /> </Alert.Heading>
                            <p style={{ marginLeft: "3%" }}>
                                En <b>DevperX</b> estamos estableciendo alianzas con nuevos clientes. Próximamente, podrás ver el trabajo realizado en esta sección.
                            </p>
                        </Alert>
                    </Col>
                </Row> : <Row>
                    {
                        DataCardProjects.works.map((e, i) => <Col md={4} className="fadeIn_no_infinite" style={{ marginBottom: "5%" }} key={i}>
                            <Card className="app_home_section_works_card_content h-100" style={{ background: "transparent" }}>
                                <Card.Header style={{ textShadow: "rgb(0, 102, 255) 0px 0px 10px" }} className="DPX__projects_item_card_content">
                                    <span style={{ color: "#f64141" }}>#</span> {e.category}
                                </Card.Header>

                                <Card.Body className="text-center ">
                                    <Image width={200} src={e.img} />
                                </Card.Body>
                                <Card.Body style={{ textAlign: "center", color: "#f64141" }}>
                                    {e.badges.map((j, k) => <Badge bg="danger" className="vibrate_slow_infinite" key={k} style={{ marginRight: "3%", marginBottom: "3%" }}>{j}</Badge>)}
                                </Card.Body>
                                <Card.Footer className="card_footer_app DPX__projects_item_card_content">
                                    {
                                        e.url_site === "-" ? "No disponible" : <a href={e.url_site} target='_blank'>
                                            Visitar página <FaExternalLinkAlt style={{ marginLeft: "3%" }} />
                                        </a>
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>)
                    }
                </Row>
            }
        </div>
    </>;
}