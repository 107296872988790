
import { NavApp } from "../components/UI/NavApp";
import { FooterApp } from "../components/UI/FooterApp";
import { routes } from "./routes";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useEffect } from "react";

export const AppRouting = () => {


    useEffect(() => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }, []);


    return <Router>

        <NavApp />

        <Routes>
            {
                routes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}
                        element={<route.element routes={route.routes} {...route} />} />
                ))
            }

            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>

        <FooterApp />

    </Router>;
}