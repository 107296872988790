import { BsWechat } from "react-icons/bs";
import { SiHelpdesk } from "react-icons/si";
import { FaThumbsUp } from "react-icons/fa6";
import { MdAutoGraph } from "react-icons/md";
import { PiGraphBold } from "react-icons/pi";
import { FaHandsHelping } from "react-icons/fa";
import web_dev from "../statics/imgs/services/02_web_dev.png";
import web_design from "../statics/imgs/services/01_web_design.png";
import dev_mobile from "../statics/imgs/services/03_dev_mobile.png";
import outsourcing from "../statics/imgs/services/00_outsourcing.png";
import analisis_data from "../statics/imgs/services/04_analisis_data.png";
import model_anim_3d from "../statics/imgs/services/05_model_anim_3d.png";
import graphic_design from "../statics/imgs/services/08_graphic_design.png";
import hosting_reseller from "../statics/imgs/services/06_hosting_reseller.png";
import community_manager from "../statics/imgs/services/07_community_manager.png";

import js from "../statics/imgs/tools/js.png"
import php from "../statics/imgs/tools/php.png"
import java from "../statics/imgs/tools/java.png"
import react from "../statics/imgs/tools/react.png"
import figma from "../statics/imgs/tools/figma.png"
import mysql from "../statics/imgs/tools/mysql.png"
import python from "../statics/imgs/tools/python.png"
import vue_js from "../statics/imgs/tools/vue_js.png"
import blender from "../statics/imgs/tools/blender.png"
import angular from "../statics/imgs/tools/angular.png"
import c_sharp from "../statics/imgs/tools/c_sharp.png"
import android from "../statics/imgs/tools/android.png"
import shopify from "../statics/imgs/tools/shopify.png"
import power_bi from "../statics/imgs/tools/power_bi.png"
import wordpress from "../statics/imgs/tools/wordpress.png"
import photoshop from "../statics/imgs/tools/photoshop.png"
import adobe_illustrator from "../statics/imgs/tools/adobe_illustrator.png"

import twitter from "../statics/imgs/socialNetwork/twitter.png";
import facebook from "../statics/imgs/socialNetwork/facebook.png";
import linkedIn from "../statics/imgs/socialNetwork/linkedIn.png";
import instagram from "../statics/imgs/socialNetwork/instagram.png";
import tiktok from "../statics/imgs/socialNetwork/tiktok.png";
import youtube from "../statics/imgs/socialNetwork/youtube.png";

export const DataCardsHome = {
    why_choose_us: [
        {
            title: "Somos Honestos",
            icon: <FaHandsHelping />
        },
        {
            title: "Somos Comprometidos",
            icon: <FaThumbsUp />
        },
        {
            title: "Marcamos la Diferencia",
            icon: <SiHelpdesk />
        }
    ],

    services: [
        {
            img: outsourcing,
            title: "Outsourcing"
        },
        {
            img: web_design,
            title: "Diseño de Aplicaciones Web"
        },
        {
            img: web_dev,
            title: "Desarrollo de Aplicaciones Web"
        },
        {
            img: dev_mobile,
            title: "Desarrollo de Aplicaciones Móvil"
        },
        {
            img: analisis_data,
            title: "Visualización y Análisis de Datos"
        },
        {
            img: model_anim_3d,
            title: "Modelo y Animación en 3D"
        },
        {
            img: hosting_reseller,
            title: "Hosting Reseller"
        },
        {
            img: community_manager,
            title: "Community Manager"
        },
        {
            img: graphic_design,
            title: "Diseño Gráfico"
        }
    ],
    img_techs: [
        js, react, vue_js, php, angular, wordpress,
        shopify, java, python, android,
        adobe_illustrator, blender,
        c_sharp, figma, mysql,
        photoshop, power_bi
    ],
    mission: [
        {
            icon: <BsWechat />,
            subtitle: "Aportar valor a partir de la comunicación",
        },
        {
            icon: <MdAutoGraph />,
            subtitle: "Asesorar y acompañar en el camino hacia el éxito",
        },
        {
            icon: <PiGraphBold />,
            subtitle: "Mantener alianzas con el cliente",
        },
    ],
    social: [
        {
            img: facebook,
            url: "https://www.facebook.com/profile.php?id=61567337194801"
        },
        {
            img: tiktok,
            url: "https://www.tiktok.com/@devper_x"
        },
        {
            img: instagram,
            url: "https://www.instagram.com/devperx_/"
        },
        {
            img: linkedIn,
            url: "https://www.linkedin.com/company/devperx/"
        },
        {
            img: twitter,
            url: "https://x.com/Devper_X"
        },
        {
            img: youtube,
            url: "https://www.youtube.com/@DevperX"
        }
    ]
};