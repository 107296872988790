import { useRef, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { AiTwotoneMail } from "react-icons/ai";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { Row, Col, Alert, Form } from "react-bootstrap";
import { DataCardsHome } from '../../_DATA/DataCardsHome';

export const HomeContact = () => {

    const [useQuoteForm, setQuoteForm] = useState({
        name_and_lastname: "", email: "", select_category: "",
        select_service: "", select_budget: "", select_consult: "", description_quote: "",
    });

    const [useQuoteFormFieldMessage, setQuoteFormFieldMessage] = useState("");
    const [useMsgCaptchaError, setMsgCaptchaError] = useState("");

    const [useQuoteFormSuccessMessage, setQuoteFormSuccessMessage] = useState("");

    const [useQuoteFormIsLoading, setQuoteFormIsLoading] = useState(false);

    const useRefFormQuote = useRef();

    const [useCaptchaResolve, setCaptchaResolve] = useState("");

    const { name_and_lastname, email, select_category,
        select_service, select_budget, select_consult, description_quote } = useQuoteForm;

    const onChangeQuoteForm = (e) => {
        setQuoteForm({
            ...useQuoteForm,
            [e.target.name]: e.target.value
        });
    }

    const onSubmitQuoteForm = async (e) => {
        e.preventDefault();

        if (name_and_lastname.trim() === "" || email.trim() === "" || select_category.trim() === "" ||
            select_service.trim() === "" || select_budget.trim() === "" ||
            select_consult.trim() === "" || description_quote.trim() === "") {
            setQuoteFormFieldMessage("Todos los campos son requeridos.");

            document.getElementById("quote_idea").scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {
                setQuoteFormFieldMessage("");
            }, 5000);
            return;
        }

        if (useCaptchaResolve.trim() === "") {
            setQuoteFormFieldMessage("Resuelve el problema para poder enviar tu consulta.");
            document.getElementById("quote_idea").scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {
                setQuoteFormFieldMessage("");
            }, 5000);
            return;
        }

        if (useCaptchaResolve === "50") {
            setQuoteFormFieldMessage("");
            setMsgCaptchaError("");
            setQuoteFormIsLoading(true);

            try {
                const response = await fetch('https://devperx.com/API/api/sendEmail.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(useQuoteForm),
                });

                const result = await response.json();
                if (result.ok) {
                    setQuoteFormSuccessMessage("Tu consulta ha sido enviada, pronto estarás recibiendo una respuesta. Atento a tu correo.");
                    document.getElementById("quote_idea").scrollIntoView({ behavior: "smooth" });
                    setQuoteFormIsLoading(false);

                    setTimeout(() => {
                        setQuoteFormSuccessMessage("");
                    }, 5000);
                    setQuoteForm({
                        name_and_lastname: "",
                        email: "",
                        select_category: "",
                        select_service: "",
                        select_budget: "",
                        select_consult: "",
                        description_quote: "",
                    });

                    setCaptchaResolve("");
                } else {
                    setQuoteFormSuccessMessage("Error al enviar el correo. Vuelve a intentarlo.");
                    setQuoteFormIsLoading(false);
                    setTimeout(() => {
                        setQuoteFormSuccessMessage("");
                    }, 5000);
                }

            } catch (error) {
                setQuoteFormSuccessMessage("Problemas con el servidor: " + error.message);
                setQuoteFormIsLoading(false);
                setTimeout(() => {
                    setQuoteFormSuccessMessage("");
                }, 5000);
            }
        } else {
            setMsgCaptchaError("El resultado de la suma es incorrecto.");
            setTimeout(() => {
                setMsgCaptchaError("");
            }, 5000);
        }
    }

    return <div className="app_home_section_contact_content">
        <Row>
            <Col md={7} className="mx-auto">
                <div id="quote_idea" className="home_main_contact">
                    <div className="title_contact_us">
                        Contacto <AiTwotoneMail />
                        <div className="title_contact_us_divisor"></div>
                    </div>

                    <div style={{ marginBottom: "5%" }}>
                        <ul style={{ listStyle: "none" }}>
                            <li style={{ textAlign: "center", fontSize: "2em", fontFamily: "font_welcome_subtitle" }}><MdOutlineDoubleArrow /> hola@devperx.com</li>
                        </ul>
                    </div>

                    {
                        useQuoteFormIsLoading ? <div style={{ textAlign: "center" }}>
                            <span className="loader"></span>
                            <span style={{ fontWeight: "bold", letterSpacing: "3px" }}>Enviando...</span>
                        </div> : null
                    }

                    {
                        useQuoteFormSuccessMessage ? <Alert className="fadeIn_no_infinite alert_success_send_form">
                            {useQuoteFormSuccessMessage}
                        </Alert> : null
                    }

                    <div style={{ display: `${useQuoteFormIsLoading ? "none" : "block"}`, filter: `grayscale(${useQuoteFormIsLoading ? 1 : 0})` }}>
                        <form ref={useRefFormQuote} onSubmit={onSubmitQuoteForm} >

                            {useQuoteFormFieldMessage ? <Alert class="fadeIn_no_infinite alert_error_fields_send_form">
                                {useQuoteFormFieldMessage}
                            </Alert> : null}

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="label_input" htmlFor="name_and_lastname">Nombre y Apellido</Form.Label>
                                        <Form.Control type="text" className="input_contact" id="name_and_lastname" name="name_and_lastname" value={name_and_lastname} onChange={onChangeQuoteForm} placeholder="Ingresa tu Nombre y Apellido" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="label_input" htmlFor="email">Correo electrónico</Form.Label>
                                        <Form.Control type="email" className="input_contact" id="email" name="email" value={email} onChange={onChangeQuoteForm} placeholder="Ingresa tu Email" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label className="label_input" htmlFor="select_category">Seleccionar Categoría</Form.Label>
                                        <Form.Select className="input_contact" id="select_category" name="select_category" value={select_category} onChange={onChangeQuoteForm}>
                                            <option defaultValue="">Seleccionar</option>
                                            {
                                                ["Empresa", "PYMEs", "Instituto", "Gubernamental", "Personal", "Influencer", "Otro"].map((e, i) => <option key={i} value={e}>{e}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="label_input" htmlFor="select_service">Seleccionar servicio</Form.Label>
                                        <Form.Select className="input_contact" id="select_service" name="select_service" value={select_service} onChange={onChangeQuoteForm}>
                                            <option defaultValue="">Seleccionar</option>
                                            {
                                                DataCardsHome.services.map((e, i) => <option key={i} value={e.title}>{e.title}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label className="label_input" htmlFor="select_budget">Seleccionar Presupuesto</Form.Label>
                                        <Form.Select className="input_contact" id="select_budget" name="select_budget" value={select_budget} onChange={onChangeQuoteForm}>
                                            <option defaultValue="">Seleccionar</option>
                                            {
                                                ["Hasta $100.000 | $100usd", "Hasta $200.000 | $200usd", "Hasta $300.000 | $300usd", "Hasta $400.000 | $400usd", "Hasta $500.000 | $500usd", "Hasta $600.000 | $600usd", "Hasta $700.000 | $700usd", "Invertir más en el proyecto"].map((e, i) => <option key={i} value={e}>{e}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="label_input" htmlFor="select_consult">Seleccionar consulta</Form.Label>
                                        <Form.Select className="input_contact" id="select_consult" name="select_consult" value={select_consult} onChange={onChangeQuoteForm}>
                                            <option defaultValue="">Seleccionar</option>
                                            {
                                                ["Quiero comenzar de inmediato", "Comenzaré dentro de 1 mes", "Comenzaré en este año", "Solo quiero consultar"].map((e, i) => <option key={i} value={e}>{e}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3">
                                    <Form.Label className="label_input" htmlFor="description_quote">Cuéntanos lo que tienes en mente</Form.Label>
                                    <Form.Control as="textarea" className="input_contact input_textarea" id="description_quote" name="description_quote" value={description_quote} onChange={onChangeQuoteForm} placeholder="En base al servicio seleccionado, cuéntanos más sobre lo que querés llevar a cabo..." />
                                </Form.Group>
                            </Row>

                            {
                                name_and_lastname &&
                                    email &&
                                    select_category &&
                                    select_service &&
                                    select_budget &&
                                    select_consult &&
                                    description_quote ? <Row>
                                    <div className="contact_resolve_my_captcha">
                                        <span >25 + 25 =</span> <input className="input_contact_resolve" type='number' placeholder='Respuesta'
                                            value={useCaptchaResolve} onChange={(e) => setCaptchaResolve(e.target.value)} />
                                    </div>

                                    {
                                        useMsgCaptchaError ? <div style={{ textAlign: "center" }}>
                                            <span style={{ fontWeight: "bold", letterSpacing: "3px" }}>{useMsgCaptchaError}</span>
                                        </div> : null
                                    }
                                </Row> : null
                            }

                            <div style={{ textAlign: "center", marginTop: "5%" }}>
                                <button className="button btn_quote_send">
                                    |Consultar <BiMailSend />
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Col>
        </Row>
    </div>;
}