import "swiper/css";
import App from './App';
import React from 'react';
import "./statics/css/mq.css";
import 'swiper/css/navigation';
import "./statics/css/app.css";
import "swiper/css/pagination";
import "./statics/css/_anim.css";
import "./statics/css/_fonts.css";
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);