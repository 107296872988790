import { Image } from "react-bootstrap";
import { DataCardsHome } from "../../_DATA/DataCardsHome";

export const HomeSomeTools = () => {
    return <div>
        <div className="app_home_section_some_tools_content">
            <div className="home_main_tools">
                <div className="title_tools">
                    Algunas de las herramientas con las que trabajamos
                </div>
                <div className="imgs_tools_content">
                    {
                        DataCardsHome.img_techs.map((e, i) => <div key={i} className="img_tool" >
                            <Image width={50} src={e} />
                        </div>)
                    }
                </div>
            </div>
        </div>
    </div>;
}