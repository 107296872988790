import dariogil_inmobiliaria from "../statics/imgs/_projects/works/dario_gil_inmobiliaria.png";

// Diseño web | Desarrollo web | Diseño mobile | Desarrollo mobile
export const DataCardProjects = {
    works: [
        {
            category: "Desarrollo Web",
            url_site: "https://dariogil.com/",
            img: dariogil_inmobiliaria,
            badges: ["React JS", "PHP", "MySQL"]
        },
    ],

    projects: [
        // {
        //     category: "Desarrollo Web",
        //     url_site: "https://dariogil.com/",
        //     img: dariogil_inmobiliaria,
        //     badges: ["React JS", "PHP", "MySQL"]
        // },
    ],

    sales: [
        // {
        //     category: "Desarrollo Web",
        //     url_site: "https://dariogil.com/",
        //     img: dariogil_inmobiliaria,
        //     badges: ["React JS", "PHP", "MySQL"]
        // },
    ],
};