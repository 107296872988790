import { HomeService } from "./HomeServices";
import { Col, Row, Card } from "react-bootstrap";
import { HomeOurMission } from "./HomeOurMission";
import { DataCardsHome } from "../../_DATA/DataCardsHome";

export const HomeWhoChoose = () => {

    return <div className="DPX__choose_service_mission_content">

        {/* ------------------ CHOOSE US */}
        <div className="choose_us_content">
            <div className="title_choose_us">
                <span className="color_red">¿</span>Por qué elegirnos<span className="color_red">?</span>
                <div className="border_hr"></div>
            </div>

            <div className="choose_us_card_content">
                <Row>
                    {
                        DataCardsHome.why_choose_us.map((e, i) => <Col md={4} className="mb-4" key={i}>
                            <Card className="card_content" style={{ background: "transparent" }}>
                                <Card.Header className="card_header">{e.title}</Card.Header>
                                <Card.Body className="card_body">
                                    <div className="icon_cute">
                                        {e.icon}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>)
                    }
                </Row>
            </div>
        </div>

        {/* ------------------ SERVICES */}
        <div className="services_content">
            <HomeService />
        </div>

        {/* ------------------ MISSIONS */}
        <div className="missions_content">
            <HomeOurMission />
        </div>

    </div>;
}