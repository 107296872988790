import { LuArrowDownSquare } from "react-icons/lu";

export const HomeWelcome = () => {
    const onClickDown = () => {
        document.getElementById("id_services").scrollIntoView({ behavior: "smooth" });
    }

    return <div className="DPX__home_welcome_content">
        <div className="title_content">
            Conectamos tus ideas con la realidad. <br />
            Sé parte de nuestra aventura.
        </div>

        <div className="subtitle_content vibrate_slow_infinite">
            Creamos facilidad, creamos soluciones, creamos algo mucho más que Software, creamos alianzas. <br />
            Sé parte de nuestro camino...
        </div>
        <div className="arrow_down">
            <LuArrowDownSquare onClick={onClickDown} style={{ marginRight: "5%" }} />
        </div>
    </div>;
}