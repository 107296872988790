import { HomeWelcome } from "./HomeWelcome"
import { HomeContact } from "./HomeContact";
import { HomeSomeTools } from "./HomeSomeTools";
import { HomeWhoChoose } from "./HomeWhoChoose";

export const HomeMain = () => {
    return <>
        <HomeWelcome />
        <HomeWhoChoose />
        <HomeSomeTools />
        <HomeContact />
    </>;
}