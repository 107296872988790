import { Card, Col, Row } from "react-bootstrap";
import { DataCardsHome } from "../../_DATA/DataCardsHome";

export const HomeOurMission = () => {

    return <>

        <div className="title_missions">
            <span className="color_red">{"{"}</span>MISIÓN<span className="color_red">{"}"}</span>
        </div>

        <div className="card_content">
            <Row>
                {
                    DataCardsHome.mission.map((e, i) => <Col md={4} className="mb-4">
                        <Card className="card_item_content" style={{ background: "transparent" }}>
                            <Card.Body className="card_body">
                                <div className="icon_cute">
                                    {e.icon}
                                </div>
                            </Card.Body>
                            <Card.Header className="card_subheader">
                                {e.subtitle}
                            </Card.Header>
                        </Card>
                    </Col>)
                }
            </Row>
        </div>
    </>;
}