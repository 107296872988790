import { Image } from "react-bootstrap";
import { DataCardsHome } from "../../_DATA/DataCardsHome";

export const FooterApp = () => {

    const startYear = 2024;
    const currentYear = new Date().getFullYear();

    return <div className="footer_contact">
        <div className="content_footer">
            <div className="social_network_content">
                {
                    DataCardsHome.social.map((e, i) => <a key={i} href={e.url} target="_blank" rel="noreferrer">
                        <div key={i} className="img_social">
                            <Image width={30} src={e.img} />
                        </div>
                    </a>)
                }
            </div>
            &copy;DevperX <span style={{ color: "#f10036" }}>[{startYear === currentYear ? null : `${currentYear} - `} {startYear}] </span> - Todos los derechos reservados 🚀
        </div>
    </div>;
}