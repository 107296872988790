import { HomeMain } from "../components/Home/HomeMain";
import { WorksMain } from "../components/Works/WorksMain";
import { SalesMain } from "../components/Sales/SalesMain";
import { ProjectsMain } from "../components/Projects/ProjectsMain";


export const routes = [
    {
        path: "/",
        element: HomeMain,
        exact: true
    },
    {
        path: "/works",
        element: WorksMain,
        exact: true
    },
    {
        path: "/projects",
        element: ProjectsMain,
        exact: true
    },
    {
        path: "/sales",
        element: SalesMain,
        exact: true
    }
];